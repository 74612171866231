import { datadogLogs } from "@datadog/browser-logs";

export const datadogInit = async function ({
  partnerName,
}: {
  partnerName: string;
}) {
  if (process.env.NEXT_PUBLIC_DATADOG) {
    datadogLogs.init({
      clientToken: process.env.NEXT_PUBLIC_DATADOG,
      site: "datadoghq.com",
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: "marketing-monorepo",
      env: process.env.NODE_ENV,
      version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    });
  }
  if (
    process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID &&
    process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN
  ) {
    const DD = await import("@datadog/browser-rum");
    DD.datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
      // site refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: "datadoghq.com",
      service: `${partnerName}-marketing`,
      env: process.env.NEXT_PUBLIC_ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 20,
      sessionReplaySampleRate: 10,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
  }
};
