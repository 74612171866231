"use client";

import { datadogLogs } from "@datadog/browser-logs";
import { authStore } from "@kaplan-labs/up-auth-api-client";
import { useStore } from "@nanostores/react";

import { themeStore } from "./library-theme-context";
import { persistentAtom } from "./persistant-store";

import type { Profile, RelatedProfiles } from "./types/profile";
import type { HTTPError } from "ky";

export const SESSION_PROFILE_KEY = "__upprofile";
export const UP_PROFILE_DID_CHANGE = "up_profile_did_change";
const BASE_URL = process.env["NEXT_PUBLIC_HALL_OF_RECORDS_URI"] as string;

export type ProfileContextType = {
  isLoading: boolean;
  didError: boolean;
  data?: Profile;
};

export const profileStore = persistentAtom<ProfileContextType>(
  SESSION_PROFILE_KEY,
  {
    isLoading: false,
    didError: false,
    data: undefined,
  },
  {
    encode: JSON.stringify,
    decode: JSON.parse,
  },
);

let RETRY_COUNT = 0;
const RETRY_INTERVAL = 1000;
const MAX_RETRY_COUNT = 10;

const _emitEvent = (eventName: string, payload: unknown) => {
  const event = new CustomEvent(eventName, {
    detail: payload,
  });

  document.dispatchEvent(event);
};

const _getProfile = async function (baseURL: string, partnerID: string) {
  const $auth = authStore.get();

  try {
    const data = await $auth.client
      .get(`${baseURL}/profiles/${partnerID}`)
      .json<Profile>();

    return {
      data,
    };
  } catch (_err: unknown) {
    const error = _err as HTTPError;
    if (error.response.status === 404) {
      await new Promise((r) => setTimeout(r, RETRY_INTERVAL));
      return Promise.resolve({ data: null, error });
    }
    return Promise.reject(error);
  }
};

export const _fetchCurrentUserProfile = async (
  baseURL: string,
  partnerID: string,
): Promise<[undefined | Profile, undefined | HTTPError]> => {
  const $auth = authStore.get();
  let payload;

  try {
    let resp = null;
    do {
      RETRY_COUNT += 1;
      resp = await _getProfile(baseURL, partnerID);
    } while (resp && "error" in resp && RETRY_COUNT < MAX_RETRY_COUNT);

    if (resp && "error" in resp) {
      return Promise.resolve([undefined, resp.error]);
    }

    payload = resp.data;

    if (resp.data?.type === "Student" || !("type" in resp.data)) {
      return Promise.resolve([resp.data, undefined]);
    }
  } catch (err: unknown) {
    return Promise.resolve([undefined, err as HTTPError]);
  }

  try {
    const relationships = await $auth.client
      .get(`${baseURL}/profiles/relationships/${partnerID}`)
      .json<RelatedProfiles>();

    return Promise.resolve([
      {
        ...payload,
        relatedStudents: relationships.relatedStudents,
      },
      undefined,
    ]);
  } catch (err) {
    return Promise.resolve([undefined, err as HTTPError]);
  }
};

export const sync = async () => {
  const $auth = authStore.get();
  const $theme = themeStore.get();
  const $profile = profileStore.get();

  if (!$auth.currentUser || !$theme.partnerID) {
    profileStore.set({
      isLoading: false,
      didError: false,
      data: undefined,
    });
    return;
  }

  profileStore.set({
    isLoading: true,
    didError: false,
    data: $profile?.data,
  });
  const [data, error] = await _fetchCurrentUserProfile(
    BASE_URL,
    $theme.partnerID,
  );

  if (error) {
    profileStore.set({
      isLoading: false,
      didError: true,
      data: undefined,
    });
    datadogLogs.logger.error("Could not load profile", {
      url: window.location.href,
      attempts: RETRY_COUNT,
    });
    _emitEvent(UP_PROFILE_DID_CHANGE, { profile: undefined });
  } else {
    profileStore.set({
      isLoading: false,
      didError: false,
      data,
    });
    _emitEvent(UP_PROFILE_DID_CHANGE, { profile: data });
  }
};

export const profileInit = function () {
  authStore.listen(sync);
  themeStore.listen(sync);
  sync();
};

/*
  React hook to force renders when profile changes
*/
export const useProfile = function () {
  return useStore(profileStore);
};
