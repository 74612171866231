import "../styles/index.scss";

import { themeStore } from "@up/data";
import { CustomNextApp } from "@up/next-customization";

import { DEFAULT_SEO } from "~/lib/seo";
import { LIBRARY_THEME } from "~/lib/theme";

themeStore.set(LIBRARY_THEME);

export default function _CustomApp(customAppProps) {
  return (
    <>
      {CustomNextApp({
        ...customAppProps,
        defaultSEO: DEFAULT_SEO,
      })}
    </>
  );
}
