"use client";

import { useEffect } from "react";

import { ownerInit, profileInit, useTheme } from "@up/data";

import { brazeInit } from "./braze-init";
import { datadogInit } from "./datadog-init";
import { heapInit } from "./heap-init";

export const FrontendInit = () => {
  const theme = useTheme();

  useEffect(() => {
    brazeInit();
    heapInit();
    if (theme.analyticsPartnerName) {
      datadogInit({
        partnerName: theme.analyticsPartnerName,
      });
    }

    profileInit();
    ownerInit();
  }, []);
  return null;
};
