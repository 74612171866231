import NiceModal from "@ebay/nice-modal-react";
import { setBaseURL } from "@kaplan-labs/up-auth-api-client";
import { PrismicPreview } from "@prismicio/next";
import { SpeedInsights } from "@vercel/speed-insights/next";
import Head from "next/head";
import Router, { useRouter } from "next/router";

import { gtmScript, pageview } from "@up/analytics";
import { CartProvider } from "@up/checkout";
import { getRepositoryName } from "@up/data";
import { SetupModalTriggers } from "@up/lead-capture";

import { FrontendInit } from "./frontend-init";
import { TestingEnvBanner } from "./testing-env-banner";

import type { AppProps } from "next/app";

setBaseURL(process.env["NEXT_PUBLIC_HALL_OF_RECORDS_URI"] as string);

// Virtual Pageview for GTM
Router.events.on("routeChangeComplete", (url) => pageview(url));

type AppPropsWithExtras = AppProps & {
  siteWrapperClassName?: string;
  defaultSEO: {
    title: string;
    description: string;
    openGraph: {
      type: string;
      url: string;
      image: string;
      imageWidth: string;
      imageHeight: string;
    };
    twitter: {
      cardType: string;
    };
  };
};

export function CustomNextApp({
  Component,
  pageProps,
  defaultSEO,
  siteWrapperClassName = "",
}: AppPropsWithExtras) {
  const router = useRouter();

  const useGTM =
    process.env.NODE_ENV !== "development" &&
    process.env.NEXT_PUBLIC_GTM_TRACKING_ID;

  const endpoint = process.env.NEXT_PUBLIC_PRISMIC_API_URL as string;
  const repositoryName = getRepositoryName(endpoint);
  const page = pageProps?.page || { data: null };

  // July 23, 2022 — Jerry
  // Checking for null because Prismic currently has a bug
  // with default values not working on select and boolean
  const allowIndexing = (() => {
    if (!page) {
      return false;
    }

    return page.data?.allow_search_indexing === null
      ? true
      : page.data?.allow_search_indexing;
  })();

  const desc = page.data?.description;
  const title = page.data?.title;

  return (
    <>
      <Head>
        {useGTM && (
          <>
            <link rel="preconnect" href="https://www.googletagmanager.com" />
            <script
              id="gtm-script"
              dangerouslySetInnerHTML={{
                __html: gtmScript(
                  process.env.NEXT_PUBLIC_GTM_TRACKING_ID as string,
                ),
              }}
            />
          </>
        )}
        <title key="title">{title || defaultSEO.title}</title>
        <meta
          key="description"
          name="description"
          content={desc || defaultSEO.description}
        />
        <meta
          key="twitter:card"
          name="twitter:card"
          content={defaultSEO.twitter.cardType}
        />
        <meta
          key="og:url"
          property="og:url"
          content={defaultSEO.openGraph.url}
        />
        <meta
          key="og:type"
          property="og:type"
          content={defaultSEO.openGraph.type}
        />
        <meta
          key="og:title"
          property="og:title"
          content={title || defaultSEO.title}
        />
        <meta
          key="og:description"
          property="og:description"
          content={desc || defaultSEO.description}
        />
        <meta
          key="og:image"
          property="og:image"
          content={defaultSEO.openGraph.image}
        />
        <meta
          key="og:image:width"
          property="og:image:width"
          content={defaultSEO.openGraph.imageWidth}
        />
        <meta
          key="og:image:height"
          property="og:image:height"
          content={defaultSEO.openGraph.imageHeight}
        />
        {!allowIndexing && (
          <meta key="robots" name="robots" content="noindex" />
        )}
      </Head>

      <FrontendInit />

      <SetupModalTriggers className={`site-wrapper ${siteWrapperClassName}`}>
        <PrismicPreview repositoryName={repositoryName}>
          <CartProvider
            baseURL={process.env["NEXT_PUBLIC_HALL_OF_RECORDS_URI"] as string}
            router={router}
          >
            <TestingEnvBanner />
            <NiceModal.Provider>
              <Component {...pageProps} />
            </NiceModal.Provider>
          </CartProvider>
        </PrismicPreview>
      </SetupModalTriggers>

      <SpeedInsights />
      <div id="cart-portal" />
      <div id="signin-portal" />
    </>
  );
}
