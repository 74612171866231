import { Flask } from "@phosphor-icons/react";

export const TestingEnvBanner = function () {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "staging") {
    return (
      <div
        style={{
          position: "sticky",
          top: 0,
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          justifyContent: "center",
          background: "#240f6e",
          color: "white",
          padding: "0.5rem 0 0.5rem 0",
          fontSize: "0.75rem",
          textAlign: "center",
          textTransform: "uppercase",
          fontFamily: "monospace",
          letterSpacing: "0.05rem",
        }}
      >
        <Flask size={28} weight="duotone" />
        Testing Environment
      </div>
    );
  }

  return null;
};
