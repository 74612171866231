"use client";

import { atom } from "nanostores";
import { useStore } from "@nanostores/react";

import type { TLibraryTheme } from "@up/data";

export const themeStore = atom<TLibraryTheme>({
  analyticsPartnerName: "",
  buttonPrimaryClass: "",
  buttonSecondaryClass: "",
  buttonTertiaryClass: "",
  directToCheckout: false,
  heading1Class: "",
  heading2Class: "",
  heading3Class: "",
  lmsURL: "",
  partnerID: "",
  partnerPrivacyPolicy: "",
  partnerName: "",
  partnerOnlineName: "",
  primaryColor: "",
  supportEmail: "",
});

/*
  React hook to force renders when theme changes
*/
export const useTheme = () => useStore(themeStore);
