"use client";

import { authStore } from "@kaplan-labs/up-auth-api-client";
import { useStore } from "@nanostores/react";

import { deepEqual } from "./deep-equal";
import { persistentAtom } from "./persistant-store";
import { profileStore } from "./profile-context";

type OwnerStore = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

const OWNER_KEY = "__upowner";

export const ownerStore = persistentAtom<OwnerStore | undefined>(
  OWNER_KEY,
  undefined,
  {
    encode: JSON.stringify,
    decode: JSON.parse,
  },
);

const sync = () => {
  const $auth = authStore.get();
  const $profile = profileStore.get();

  if (!$auth.currentUser) {
    ownerStore.set(undefined);
    return;
  }

  if ($profile.isLoading) {
    return;
  }

  if (!$profile.data) {
    ownerStore.set(undefined);
    return;
  }

  if ($profile.data.type === "Student" || !("type" in $profile.data)) {
    ownerStore.set({
      email: $auth.currentUser.email,
    });
    return;
  }

  // Guardian with student(s), but Owner is currently set to Guardian
  const _owner = ownerStore.get();
  if (_owner?.email) {
    if (
      $profile.data.type === "Guardian" &&
      $profile.data.relatedStudents?.length &&
      _owner.email === $auth.currentUser.email
    ) {
      ownerStore.set({
        email: $profile.data.relatedStudents[0].email,
        firstName: $profile.data.relatedStudents[0].firstName ?? "",
        lastName: $profile.data.relatedStudents[0].lastName ?? "",
      });
      return;
    }
  }

  // Guardian with student(s), Owner is not currently set to Guardian
  if (_owner?.email) {
    if (
      $profile.data.type === "Guardian" &&
      $profile.data.relatedStudents?.length &&
      _owner.email !== $auth.currentUser.email
    ) {
      const students = $profile.data.relatedStudents;
      const student = students.find((s) => s.email === _owner.email);

      if (student) {
        return;
      }

      ownerStore.set(undefined);
      return;
    }
  }

  if (
    $profile?.data?.type === "Guardian" &&
    $profile?.data?.relatedStudents?.length
  ) {
    ownerStore.set({
      email: $profile.data.relatedStudents[0].email,
      firstName: $profile.data.relatedStudents[0].firstName ?? "",
      lastName: $profile.data.relatedStudents[0].lastName ?? "",
    });
    return;
  }

  // Handle case where guardian has no students
  if (
    $profile.data.type === "Guardian" &&
    !$profile.data.relatedStudents?.length
  ) {
    ownerStore.set({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      email: $auth.currentUser!.email,
    });
    return;
  }

  ownerStore.set(undefined);
  return ownerStore.get();
};

export const ownerInit = function () {
  profileStore.listen((newValue, oldValue) => {
    if (deepEqual(newValue?.data, oldValue?.data)) {
      return;
    }

    sync();
  });

  authStore.listen(sync);

  sync();
};

/*
  React hook to force renders when owner changes
*/
export const useOwner = function () {
  return useStore(ownerStore);
};
