import { authStore } from "@kaplan-labs/up-auth-api-client";

const go = function () {
  const $auth = authStore.get();

  if (window.heap && $auth.currentUser) {
    window.heap.identify($auth.currentUser.id as string);
  }
};

export const heapInit = function () {
  const isClient =
    typeof window !== "undefined" && typeof document !== "undefined";

  authStore.listen((state) => {
    if (isClient) {
      go();
    }
  });

  if (isClient) {
    go();
  }
};
