import { authStore } from "@kaplan-labs/up-auth-api-client";

import { themeStore } from "@up/data";

import type { AnalyticsData } from "./types";

export const VIRTUAL_PAGEVIEW = "virtualPageview";

export const analyticsEvent = (data: AnalyticsData, testMode = false) => {
  const $theme = themeStore.get();
  const $auth = authStore.get();

  const isVirtualPageview = data?.event === VIRTUAL_PAGEVIEW;

  const payload: Record<string, unknown> = {
    partner: $theme.analyticsPartnerName,
    url: window.location.href,
    ...data,
  };

  if ($auth.currentUser) {
    payload.user_id = $auth.currentUser.id;
    payload.user_email = $auth.currentUser.email;
  }

  const catalogueId = document.querySelector("meta#catalogueid");
  if (catalogueId) {
    payload.catalogue_id = catalogueId.getAttribute("content");
  }

  const courseName = document.querySelector("meta#coursename");
  if (courseName) {
    payload.course = courseName.getAttribute("content");
  }

  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT &&
    process.env.NEXT_PUBLIC_ENVIRONMENT === "production" &&
    !testMode &&
    window.dataLayer
  ) {
    window.dataLayer.push(payload);
  } else {
    console.log("analyticsEvent: ", payload);
  }

  if (window.heap && !isVirtualPageview) {
    // !!! Mutating payload !!!
    const event = data.event;
    delete payload.event;
    window.heap.track(event, payload);
  }
};

export const pageview = (url: string) => {
  analyticsEvent({ event: VIRTUAL_PAGEVIEW, virtualUrl: url });
};

export function gtmScript(id: string) {
  return `
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ originalLocation: window.location.href });
    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start': new Date().getTime(), event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${id}');
  `;
}
